<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form">
      <a-form-model-item label="营业执照" prop="businessLicenseImage">
        <oss-multi-upload v-model="form.businessLicenseImage" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="法人名称" prop="legalPerson" >
        <a-input v-model="form.legalPerson" placeholder="请输入法人名称" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="mobile">
        <a-input v-model="form.mobile" placeholder="请输入联系电话" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="店铺名称" prop="shopName">
        <a-input v-model="form.shopName" placeholder="请输入店铺名称" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="统一社会信用代码" prop="businessLicenseUSCI" >
        <a-input v-model="form.businessLicenseUSCI" placeholder="请输入统一社会信用代码" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="图片" prop="img">
        <oss-multi-upload v-model="form.img" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="视频" prop="video">
        <oss-multi-upload v-model="form.video" type="video" :maxCount="0" :maxSize="200" :isRemove="false"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="店铺logo" prop="logo">
        <oss-multi-upload v-model="form.logo" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="地区编码" prop="areaCode">
        <a-input v-model="form.areaCode" placeholder="请输入地区编码" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address">
        <a-input v-model="form.address" placeholder="请输入详细地址" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="经度" prop="longitude">
        <a-input v-model="form.longitude" placeholder="请输入经度" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="纬度" prop="latitude">
        <a-input v-model="form.latitude" placeholder="请输入纬度" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="客服电话,多个之间用逗号分隔" prop="servicePhone">
        <a-input v-model="form.servicePhone" placeholder="请输入客服电话,多个之间用逗号分隔" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="客服微信号" prop="serviceWeChatNumber" >
        <a-input v-model="form.serviceWeChatNumber" placeholder="请输入客服微信号" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="店家说" prop="talkInfo">
        <a-input v-model="form.talkInfo" placeholder="请输入店家说" :disabled="true" />
      </a-form-model-item>
      <!--      <a-form-model-item label="标签库id,多个之间用逗号分隔" prop="label" >-->
      <!--        <a-input v-model="form.label" placeholder="请输入标签库id,多个之间用逗号分隔" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="店铺级别" prop="level">
        <a-select v-model="form.level" placeholder="请选择店铺级别" allow-clear :disabled="true">
          <a-select-option value="1">普通店铺</a-select-option>
          <a-select-option value="2">铜牌店铺</a-select-option>
          <a-select-option value="3">银牌店铺</a-select-option>
          <a-select-option value="4">黑金店铺</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="评分值" prop="evaluationValue">
        <a-input v-model="form.evaluationValue" placeholder="请输入评分值" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="人气值" prop="popularValue">
        <a-input v-model="form.popularValue" placeholder="请输入人气值" :disabled="true" />
      </a-form-model-item>
      <!--      <a-form-model-item label="是否认证 1:已认证,0未认证" prop="isAuth" >
              <a-input v-model="form.isAuth" placeholder="请输入是否认证 1:已认证,0未认证" />
            </a-form-model-item>-->
      <a-form-model-item label="保证金" prop="isPay">
        <!--        <a-select placeholder="请选择缴纳保证金状态" v-model="form.isPay">-->
        <!--          <a-select-option value="true">已缴纳</a-select-option>-->
        <!--          <a-select-option value="false">未缴纳</a-select-option>-->
        <!--        </a-select>-->
        <a-select placeholder="请选择缴纳保证金状态" v-model="form.isPay">
          <a-select-option v-for="(d, index) in payMarginOptions" :key="index" :value="d.value">{{ d.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="营业状态" prop="businessStatus">
        <a-select style="width: 100%" v-model="form.businessStatus" placeholder="请输入标签">
          <a-select-option v-for="(item, index) in this.customDict.BusinessStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="店铺抽成比例（例如百分之一0.01）" prop="drawPercent">
        <a-input v-model="form.drawPercent" placeholder="请输入店铺抽成比例" />
      </a-form-model-item>
      <!--      <a-form-model-item label="盒装价格 元/人" prop="priceBoxed" >
            </a-form-model-item>
            <a-form-model-item label="城限价格 元/人" prop="priceCityLimit" >
            </a-form-model-item>
            <a-form-model-item label="独家价格 元/人" prop="priceSole" >
            </a-form-model-item>
            <a-form-model-item label="实景价格 元/人" prop="priceRealScene" >
            </a-form-model-item>
            <a-form-model-item label="微剧本价格 元/人" prop="priceMicro" >
            </a-form-model-item>-->
      <!--      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
              <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark" >
              <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
            </a-form-model-item>
            <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
              <a-input v-model="form.version" placeholder="请输入乐观锁" />
            </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getShop, updateShop } from '@/api/shop/shop'
import { mapGetters } from 'vuex'
import { generateFilePath, uploadObject } from '@/api/tool/alioss'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'

export default {
  name: 'CreateForm',
  props: {
    payMarginOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    OssMultiUpload
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      formTitle: '',
      fileList: [],
      fileList1: [],
      fileList2: [],
      // 表单参数
      form: {
        id: null,
        legalPerson: null,
        businessLicenseImage: null,
        mobile: null,
        inDoorImg: null,
        outDoorImg: null,
        logo: null,
        shopName: null,
        businessLicenseUSCI: null,
        areaCode: null,
        longitude: null,
        latitude: null,
        address: null,
        servicePhone: null,
        talkInfo: null,
        label: null,
        img: null,
        video: null,
        level: null,
        evaluationValue: null,
        popularValue: null,
        isAuth: null,
        isPay: null,
        businessStatus: null,
        status: 0,
        drawPercent: null,
        priceBoxed: null,
        priceCityLimit: null,
        priceSole: null,
        priceRealScene: null,
        priceMicro: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
        inDoorImg: [
          { required: true, message: '店铺内景不能为空', trigger: 'blur' }
        ],
        outDoorImg: [
          { required: true, message: '店铺外景不能为空', trigger: 'blur' }
        ],
        logo: [
          { required: true, message: '店铺logo不能为空', trigger: 'blur' }
        ],
        areaCode: [
          { required: true, message: '所在地区 标准行政区划编码(到县)不能为空', trigger: 'blur' }
        ],
        longitude: [
          { required: true, message: '经度不能为空', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: '纬度不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '详细地址不能为空', trigger: 'blur' }
        ],
        servicePhone: [
          { required: true, message: '客服电话,多个之间用逗号分隔不能为空', trigger: 'blur' }
        ],
        talkInfo: [
          { required: true, message: '店家说不能为空', trigger: 'blur' }
        ],
        popularValue: [
          { required: true, message: '人气值不能为空', trigger: 'blur' }
        ],
        isAuth: [
          { required: true, message: '是否认证 1:已认证,0未认证不能为空', trigger: 'blur' }
        ],
        isPay: [
          { required: true, message: '是否缴纳保证金 1:是,0:否不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '商家状态 1:申请中,2:正常,3:封禁不能为空', trigger: 'blur' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.fileList = []
      this.fileList1 = []
      this.fileList2 = []
      this.form = {
        id: null,
        legalPerson: null,
        businessLicenseImage: null,
        mobile: null,
        inDoorImg: null,
        outDoorImg: null,
        logo: null,
        shopName: null,
        businessLicenseUSCI: null,
        areaCode: null,
        longitude: null,
        latitude: null,
        address: null,
        servicePhone: null,
        talkInfo: null,
        label: null,
        img: null,
        video: null,
        level: null,
        evaluationValue: null,
        popularValue: null,
        isAuth: null,
        isPay: null,
        businessStatus: null,
        status: 0,
        drawPercent: null,
        priceBoxed: null,
        priceCityLimit: null,
        priceSole: null,
        priceRealScene: null,
        priceMicro: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cover'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'cover', res)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShop(id).then(response => {
        let i
        let images
        this.form = response.data
        this.open = true
        this.formTitle = '店铺详情'
        // 处理图片反显
        if (response.data.inDoorImg !== undefined && response.data.inDoorImg !== null && JSON.stringify(response.data.inDoorImg) !== '"{}"') {
          images = this.form.inDoorImg.split(',')
          for (i = 0; i < images.length; i++) {
            this.fileList.push({ status: 'done', url: images[i], uid: this.getUidRandom(), name: 'img.jpg' })
          }
        }
        // 处理图片反显
        if (response.data.outDoorImg !== undefined && response.data.outDoorImg !== null && JSON.stringify(response.data.outDoorImg) !== '"{}"') {
          images = this.form.outDoorImg.split(',')
          for (i = 0; i < images.length; i++) {
            this.fileList1.push({ status: 'done', url: images[i], uid: this.getUidRandom(), name: 'img.jpg' })
          }
        }
        // 处理图片反显
        if (response.data.img !== undefined && response.data.img !== null && JSON.stringify(response.data.img) !== '"{}"') {
          images = this.form.img.split(',')
          for (i = 0; i < images.length; i++) {
            this.fileList2.push({ status: 'done', url: images[i], uid: this.getUidRandom(), name: 'img.jpg' })
          }
        }
        this.form.isPay = this.form.isPay.toString()
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShop(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // addShop(this.form).then(response => {
            //   this.$message.success(
            //     '新增成功',
            //     3
            //   )
            //   this.open = false
            //   this.$emit('ok')
            // }).finally(() => {
            //   this.submitLoading = false
            // })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="css" scoped>
.bottom-control {
  z-index: 999;
}
</style>
